import HttpFactory from './factory';
import type {ApiCollectionResponse} from '~/models/Response/ApiCollectionResponse';
import type {Post} from '~/models/Post';

class PostListPostsRepository extends HttpFactory {
    private RESOURCE = '/post-lists';

    async index(uuid: string): Promise<ApiCollectionResponse<Post>> {
        return await this.getJson(`${this.RESOURCE}/${uuid}/posts`);
    }
}

export default PostListPostsRepository;