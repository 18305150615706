import HttpFactory from './factory';
import type {ApiCollectionResponse} from '~/models/Response/ApiCollectionResponse';
import type {Poll} from '~/models/Poll/Poll';

class PollVoteRepository extends HttpFactory {
    private RESOURCE = '/poll_votes';

    async store(params?: object): Promise<ApiCollectionResponse<Poll>> {
        return await this.postJson(`${this.RESOURCE}`, params);
    }


}

export default PollVoteRepository;