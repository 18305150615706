import {defineNuxtPlugin} from '#app';
import {repositories} from '~/repository/repositories';


export default defineNuxtPlugin((_nuxtApp) => {
    return {
        provide: {
            repository: repositories(),
        },
    };
});