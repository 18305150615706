import HttpFactory from './factory';
import type {Post} from '~/models/Post';
import type {ApiResourceResponse} from '~/models/Response/ApiResourceResponse';

class BlogRepository extends HttpFactory {
    private RESOURCE = '/blogs';

    async show(slug: string): Promise<ApiResourceResponse<Post>> {
        return await this.getJson(`${this.RESOURCE}/${slug}`);
    }
}

export default BlogRepository;