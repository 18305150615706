import HttpFactory from './factory';
import type {ApiCollectionResponse} from '~/models/Response/ApiCollectionResponse';
import type {Poll} from '~/models/Poll/Poll';
import type {ApiResourceResponse} from '~/models/Response/ApiResourceResponse';

class PollRepository extends HttpFactory {
    private RESOURCE = '/polls';

    async index(params?: object): Promise<ApiCollectionResponse<Poll>> {
        return await this.getJson(`${this.RESOURCE}`, params);
    }

    async show(id: string): Promise<ApiResourceResponse<Poll>> {
        return await this.getJson(`${this.RESOURCE}/${id}`);
    }


}

export default PollRepository;