import HttpFactory from './factory';
import type {ApiCollectionResponse} from '~/models/Response/ApiCollectionResponse';
import type {City} from '~/models/City';

class CitiesRepository extends HttpFactory {
    private RESOURCE = '/cities';

    async index(params?: object): Promise<ApiCollectionResponse<City>> {
        return await this.getJson(`${this.RESOURCE}`, params);
    }
}

export default CitiesRepository;