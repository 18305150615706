import HttpFactory from './factory';
import type {Post} from '~/models/Post';
import type {ApiResourceResponse} from '~/models/Response/ApiResourceResponse';
import type {Tag} from '~/models/Tag';
import type {ApiCollectionResponse} from '~/models/Response/ApiCollectionResponse';

class TagRepository extends HttpFactory {
    private RESOURCE = '/tags';

    async show(slug: string): Promise<ApiResourceResponse<Tag>> {
        return await this.getJson(`${this.RESOURCE}/${slug}`);
    }

    async posts(slug: string, params?: object): Promise<ApiCollectionResponse<Post>> {
        return await this.getJson(`${this.RESOURCE}/${slug}/posts`, params);
    }


}

export default TagRepository;