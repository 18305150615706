import * as Sentry from '@sentry/nuxt';

export default defineNuxtPlugin((nuxtApp) => {
    // Vue 3 component-level error handling
    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
        // Log the error details for debugging
        console.error('Vue Component Error:', error);
        console.error('Component Instance:', instance);
        console.error('Error Info:', info);
        // You can customize this with your preferred error reporting solution
        Sentry.captureException(error, {
            extra: { info, instance },
        });
        // Optionally send the error to an error tracking service
        // e.g., Sentry or custom logger
        // sendErrorToService(error, instance, info);
    };

    // Nuxt app-level error handling (client-side and server-side)
    nuxtApp.hook('app:error', (error) => {
        console.error('Nuxt App Error:', error);

        // You can customize this with your preferred error reporting solution
        Sentry.captureException(error);
        // Optionally handle the error more gracefully, e.g., display an error page
        // redirectToErrorPage();
    });

    if (import.meta.server) {

        // Server-side only: Unhandled Promise rejections and uncaught exceptions
        process.on('unhandledRejection', (reason, _promise) => {
            console.error('Unhandled Rejection (Server):', reason);
            Sentry.captureMessage(reason);
        });

        process.on('uncaughtException', (error) => {
            console.error('Uncaught Exception (Server):', error);
            Sentry.captureException(error);
        });
    }

});
