import HttpFactory from './factory';

class MenuRepository extends HttpFactory {
    private RESOURCE = '/menus';

    async index(params?: object): Promise<any> {
        return await this.getJson(`${this.RESOURCE}`, params);
    }
}

export default MenuRepository;