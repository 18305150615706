import HttpFactory from './factory';
import type {ApiResourceResponse} from '~/models/Response/ApiResourceResponse';
import type {ArchivedPost} from '~/models/ArchivedPost';

class ArchivedPostsRepository extends HttpFactory {
    private RESOURCE = '/archived-posts';

    async show(id?: string): Promise<ApiResourceResponse<ArchivedPost>> {
        return await this.getJson(`${this.RESOURCE}/${id}`);
    }
}

export default ArchivedPostsRepository;