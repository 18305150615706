import type {$Fetch} from 'nitropack';

class HttpFactory {
    private readonly $fetch: $Fetch;

    constructor(fetcher: $Fetch) {
        this.$fetch = fetcher;
    }

    async getJson<T>(url: string, params?: object, extras = {}): Promise<T> {
        return await this.$fetch(url, {
            method: 'GET',
            params: params,
            ...extras,
        });
    }

    /**
     * method - GET, POST, PUT
     * URL
     **/
    async postJson<T>(url: string, data?: object, extras = {}): Promise<T> {
        return await this.$fetch(url, {
            method: 'POST',
            body: data,
            ...extras,
        });
    }
}

export default HttpFactory;