import HttpFactory from './factory';
import type {ApiCollectionResponse} from '~/models/Response/ApiCollectionResponse';

import type {YoutubeVideo} from '~/models/Youtube/YoutubeVideo';

class YoutubeRepository extends HttpFactory {
    private RESOURCE = '/youtube_playlist';

    async show(uuid: string, params? : object): Promise<ApiCollectionResponse<YoutubeVideo>> {
        return await this.getJson(`${this.RESOURCE}/${uuid}`, params);
    }
}

export default YoutubeRepository;