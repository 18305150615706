import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as applicationsM6iobzTEvwMeta } from "/opt/buildhome/repo/pages/applications.vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as flightseGQzaZ6f2ZMeta } from "/opt/buildhome/repo/pages/flights.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as index5EsHhG3F2DMeta } from "/opt/buildhome/repo/pages/media/index.vue?macro=true";
import { default as receive_45notificationsxGBnSujdWnMeta } from "/opt/buildhome/repo/pages/receive-notifications.vue?macro=true";
import { default as indexJeTNcS9ILPMeta } from "/opt/buildhome/repo/pages/tag/[slug]/index.vue?macro=true";
import { default as WeatherForecastzNRcflW8XAMeta } from "/opt/buildhome/repo/custom_pages/WeatherForecast.vue?macro=true";
import { default as PersonalDataPolicybgH4HnXhOJMeta } from "/opt/buildhome/repo/custom_pages/PersonalDataPolicy.vue?macro=true";
import { default as TermsAndConditionsCwI0sz7ZGUMeta } from "/opt/buildhome/repo/custom_pages/TermsAndConditions.vue?macro=true";
import { default as SiteCreditsyt6E2ftdS9Meta } from "/opt/buildhome/repo/custom_pages/SiteCredits.vue?macro=true";
import { default as indexrX8yO1Ek2jMeta } from "/opt/buildhome/repo/custom_pages/job_listings/index.vue?macro=true";
import { default as viewoVMCqw2y3ZMeta } from "/opt/buildhome/repo/custom_pages/job_listings/view.vue?macro=true";
import { default as indexOhG1awcY7VMeta } from "/opt/buildhome/repo/custom_pages/news/index.vue?macro=true";
import { default as indexkqiMnS4dD5Meta } from "/opt/buildhome/repo/custom_pages/news/category/index.vue?macro=true";
import { default as viewUMUhFYnWwPMeta } from "/opt/buildhome/repo/custom_pages/news/category/view.vue?macro=true";
import { default as viewotrNkvKmv2Meta } from "/opt/buildhome/repo/custom_pages/news/archive/view.vue?macro=true";
import { default as indexQe2BLQrIt1Meta } from "/opt/buildhome/repo/custom_pages/blog/index.vue?macro=true";
import { default as indexiI2AMCAKiSMeta } from "/opt/buildhome/repo/custom_pages/blog/author/index.vue?macro=true";
import { default as viewmxJ29Gjx9sMeta } from "/opt/buildhome/repo/custom_pages/blog/author/view.vue?macro=true";
import { default as indexBSFhiF9vPPMeta } from "/opt/buildhome/repo/custom_pages/opinions/index.vue?macro=true";
import { default as index9fWkjiBtJKMeta } from "/opt/buildhome/repo/custom_pages/opinions/slug/index.vue?macro=true";
import { default as indexx8C96S12QfMeta } from "/opt/buildhome/repo/custom_pages/lifestyle/index.vue?macro=true";
import { default as indexECr7pJnOzcMeta } from "/opt/buildhome/repo/custom_pages/lifestyle/category/index.vue?macro=true";
import { default as indexYspvCzoUshMeta } from "/opt/buildhome/repo/custom_pages/polls/index.vue?macro=true";
import { default as viewNuMrWsOVqZMeta } from "/opt/buildhome/repo/custom_pages/polls/view.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "applications",
    path: "/applications",
    component: () => import("/opt/buildhome/repo/pages/applications.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "flights",
    path: "/flights",
    component: () => import("/opt/buildhome/repo/pages/flights.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/opt/buildhome/repo/pages/media/index.vue")
  },
  {
    name: "receive-notifications",
    path: "/receive-notifications",
    component: () => import("/opt/buildhome/repo/pages/receive-notifications.vue")
  },
  {
    name: "tag-slug",
    path: "/tag/:slug()",
    component: () => import("/opt/buildhome/repo/pages/tag/[slug]/index.vue")
  },
  {
    name: "weather",
    path: "/weather",
    component: () => import("/opt/buildhome/repo/custom_pages/WeatherForecast.vue")
  },
  {
    name: "personal-data-policy",
    path: "/personal-data-policy",
    component: () => import("/opt/buildhome/repo/custom_pages/PersonalDataPolicy.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/opt/buildhome/repo/custom_pages/TermsAndConditions.vue")
  },
  {
    name: "site-credits",
    path: "/site-credits",
    component: () => import("/opt/buildhome/repo/custom_pages/SiteCredits.vue")
  },
  {
    name: "job-listings-index",
    path: "/aggelies",
    component: () => import("/opt/buildhome/repo/custom_pages/job_listings/index.vue")
  },
  {
    name: "job-listings-view",
    path: "/aggelies/:id/:slug",
    component: () => import("/opt/buildhome/repo/custom_pages/job_listings/view.vue")
  },
  {
    name: "news",
    path: "/:category",
    meta: {"middleware":["validate-category"]},
    component: () => import("/opt/buildhome/repo/custom_pages/news/index.vue")
  },
  {
    name: "news-category-index",
    path: "/:category/:sub_category",
    meta: {"middleware":["validate-category"]},
    component: () => import("/opt/buildhome/repo/custom_pages/news/category/index.vue")
  },
  {
    name: "news-category-view",
    path: "/:category/:sub_category*/:id(\\d+)/:slug?",
    meta: {"middleware":["validate-category"]},
    component: () => import("/opt/buildhome/repo/custom_pages/news/category/view.vue")
  },
  {
    name: "news-category-view-by-id",
    path: "/:category/:sub_category*/:slug([a-zA-Z0-9+\\\\-]+)/:id(\\d+)",
    meta: {"middleware":["validate-category"]},
    component: () => import("/opt/buildhome/repo/custom_pages/news/category/view.vue")
  },
  {
    name: "archived-news-view",
    path: "/archive/:categories*/:id(\\d+)",
    component: () => import("/opt/buildhome/repo/custom_pages/news/archive/view.vue")
  },
  {
    name: "blogs-index",
    path: "/blog",
    component: () => import("/opt/buildhome/repo/custom_pages/blog/index.vue")
  },
  {
    name: "blogs-author-index",
    path: "/blog/:author_slug",
    component: () => import("/opt/buildhome/repo/custom_pages/blog/author/index.vue")
  },
  {
    name: "blogs-author-view",
    path: "/blog/:extra_parameters(.*)*/:id/:slug",
    component: () => import("/opt/buildhome/repo/custom_pages/blog/author/view.vue")
  },
  {
    name: "opinions",
    path: "/opinions",
    component: () => import("/opt/buildhome/repo/custom_pages/opinions/index.vue")
  },
  {
    name: "opinions-slug",
    path: "/opinions/:query_params(.*)*",
    component: () => import("/opt/buildhome/repo/custom_pages/opinions/slug/index.vue")
  },
  {
    name: "lifestyle-index",
    path: "/lifestyle",
    component: () => import("/opt/buildhome/repo/custom_pages/lifestyle/index.vue")
  },
  {
    name: "lifestyle-subcategory",
    path: "/lifestyle/:category/:sub_category",
    component: () => import("/opt/buildhome/repo/custom_pages/lifestyle/category/index.vue")
  },
  {
    name: "polls-index",
    path: "/polls",
    component: () => import("/opt/buildhome/repo/custom_pages/polls/index.vue")
  },
  {
    name: "polls-view",
    path: "/polls/:id",
    component: () => import("/opt/buildhome/repo/custom_pages/polls/view.vue")
  }
]