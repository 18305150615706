import HttpFactory from './factory';
import type {ApiCollectionResponse} from '~/models/Response/ApiCollectionResponse';
import type {RssFeedChannel} from '~/models/RssFeedChannel';
import type {ApiResourceResponse} from '~/models/Response/ApiResourceResponse';

class RssFeedRepository extends HttpFactory {
    private RESOURCE = '/rss-feed';

    async index(params?: object): Promise<ApiCollectionResponse<RssFeedChannel>> {
        return await this.getJson(`${this.RESOURCE}`, params);
    }

    async show(uuid: string, params?: object): Promise<ApiResourceResponse<RssFeedChannel>> {
        return await this.getJson(`${this.RESOURCE}/${uuid}`, params);
    }
}

export default RssFeedRepository;