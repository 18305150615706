export default defineNuxtPlugin((_nuxtApp) => {

    const refreshAllSlots = () => {
        googletag.cmd.push(() => {
            googletag.pubads().clear();
            googletag.pubads().refresh();
        });
    };

    // eslint-disable-next-line init-declarations
    let lastRefresh: any;
    // eslint-disable-next-line init-declarations
    let windowResizeId: any;
    // eslint-disable-next-line init-declarations
    let windowWidth: any;

    const doneResizingAnyway = () => {
        refreshAllSlots();
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const doneResizing = () => {
        const currentWidth = window.innerWidth;

        if (currentWidth >= 1243 && lastRefresh === 'med') {
            lastRefresh = 'big';
            refreshAllSlots();
            return;
        }

        if (currentWidth <= 1243 && currentWidth >= 1008 && lastRefresh === 'big') {
            lastRefresh = 'med';
            refreshAllSlots();
            
        }

        // Add logic for other resize dimensions if needed
    };

    const handleResize = () => {
        if (windowWidth !== window.innerWidth) {
            windowWidth = window.innerWidth;
            clearTimeout(windowResizeId);
            windowResizeId = setTimeout(doneResizingAnyway, 500);
        }
    };

    const handleOrientationChange = () => {
        clearTimeout(windowResizeId);
        windowResizeId = setTimeout(doneResizingAnyway, 500);
    };

    if (import.meta.client) {
        windowWidth = window.innerWidth;

        if (windowWidth >= 1243) {
            lastRefresh = 'big';
        } else if (windowWidth >= 1008) {
            lastRefresh = 'med';
        } else if (windowWidth > 0) {
            lastRefresh = 'small';
        }

        const osDevice = /android|webos|iphone|ipad|ipod|blackberry|iemobile|linux armv7l|opera mini/i.test(navigator.platform);

        if (!osDevice) {
            window.addEventListener('resize', handleResize);
        } else {
            window.addEventListener('orientationchange', handleOrientationChange);
        }
    }
});
