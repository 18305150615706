import HttpFactory from './factory';
import type {Post} from '~/models/Post';
import type {ApiResourceResponse} from '~/models/Response/ApiResourceResponse';

class SearchRepository extends HttpFactory {
    private RESOURCE = '/search';

    async search(params? : object): Promise<ApiResourceResponse<Post>> {
        return await this.getJson(`${this.RESOURCE}`, params);
    }
}

export default SearchRepository;