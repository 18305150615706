import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export default defineNuxtPlugin((nuxtApp) => {
    if (import.meta.client) {
        const config = useRuntimeConfig().public;

        const appEnv = useRuntimeConfig().public.appEnv;
        window.Pusher = Pusher;
        Pusher.logToConsole = appEnv === 'local';

        const echo = new Echo({
            broadcaster: 'reverb',
            key: config.reverbAppKey,
            cluster: config.reverbCluster,
            wsHost: config.reverbHost,
            wsPort: config.reverbPort,
            wssPort:config.reverbPort,
            forceTLS: true,
            enabledStats: true,
            enabledTransports: ['ws', 'wss'],
        });

        // Add error handling
        echo.connector.pusher.connection.bind('error', (err) => {
            console.error('Echo connection error:', err);
        });

        nuxtApp.provide('echo', echo);
    }
});