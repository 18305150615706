import HttpFactory from './factory';
import type {ApiCollectionResponse} from '~/models/Response/ApiCollectionResponse';
import type {PostUpdate} from '~/models/PostUpdate';

class PostUpdatesRepository extends HttpFactory {
    private RESOURCE = '/post/:id/post_updates';


    async index(id: string, params?: object): Promise<ApiCollectionResponse<PostUpdate>> {
        return await this.getJson(`${this.RESOURCE.replace(':id', id)}`, params);
    }

}

export default PostUpdatesRepository;