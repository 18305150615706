import HttpFactory from './factory';
import type {ApiCollectionResponse} from '~/models/Response/ApiCollectionResponse';
import type RealEstateListing from '~/models/RealEstateListing';

class RealEstateRepository extends HttpFactory {
    private RESOURCE = '/real-estate-listings';

    async index(params?: object): Promise<ApiCollectionResponse<RealEstateListing>> {
        return await this.getJson(`${this.RESOURCE}`, params);
    }
}

export default RealEstateRepository;