export default defineNuxtPlugin((_nuxtApp) => {
    if (import.meta.client && 'serviceWorker' in navigator) {
        navigator.serviceWorker.register('/sw.js')
            .then((_registration) => {
            })
            .catch((error) => {
                console.error('Service Worker registration failed:', error);
            });
    }
});
